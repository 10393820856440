//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-800:_1652,_3140,_364,_8604,_3316,_5132,_6768,_8368;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-800')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-800', "_1652,_3140,_364,_8604,_3316,_5132,_6768,_8368");
        }
      }catch (ex) {
        console.error(ex);
      }